import { createSelector } from '@reduxjs/toolkit';

import { Dataset } from 'actions/datasetActions';
import { OPERATION_TYPES } from 'constants/types';
import { EmbedReduxState } from 'embeddedContent/reducers/rootReducer';
import { getEmbedDashboardConfig } from 'reducers/thunks/dashboardDataThunks/utils';
import * as RD from 'remotedata';
import { ReportBuilderReduxState } from 'reportBuilderContent/reducers/rootReducer';
import { DASHBOARD_ELEMENT_TYPES } from 'types/dashboardTypes';
import { DashboardVersion } from 'types/dashboardVersion';
import { ResourceDataset } from 'types/exploResource';
import { getDashboardLinkForDataset, isInvalidLinkCombo } from 'utils/filterLinking';
import { mapValues, keyBy } from 'utils/standard';

import { AllStates, DashboardStates, ReduxState, ReportBuilderStates } from './rootReducer';

export const getEditableDatasets = createSelector(
  (state: ReduxState) => state.dashboardEditConfig.config?.datasets,
  (state: ReduxState) => {
    return RD.getOrDefault(state.dashboardEditConfig.versionHierarchy, undefined)
      ?.dashboardVersions;
  },
  (state: ReduxState) => {
    return RD.getOrDefault(state.dashboardEditConfig.versionHierarchy, undefined)?.rootDashboardId;
  },
  (
    datasets,
    dashboardVersions: Record<number, DashboardVersion> | undefined,
    rootDashboardId: number | undefined,
  ): Record<string, Dataset> => {
    if (dashboardVersions && rootDashboardId) {
      const rootDashboardVersion = dashboardVersions[rootDashboardId];
      if (!rootDashboardVersion) {
        return {};
      }
      return rootDashboardVersion.configuration.datasets;
    }
    return datasets ?? {};
  },
);

export const getSelectedDataPanel = createSelector(
  (state: ReduxState) => state.dashboardInteractions.selectedItem,
  (state: ReduxState) => state.dashboardEditConfig.config?.data_panels,
  (selectedItem, dataPanels) => {
    if (selectedItem?.type !== DASHBOARD_ELEMENT_TYPES.DATA_PANEL) return;
    return dataPanels?.[selectedItem.id];
  },
);

export const getSelectedDataPanelData = createSelector(
  (state: ReduxState) => state.dashboardInteractions.selectedItem,
  (state: ReduxState) => state.dashboardData.dataPanelData,
  (selectedItem, dataPanelData) => {
    if (selectedItem?.type !== DASHBOARD_ELEMENT_TYPES.DATA_PANEL) return;
    return dataPanelData[selectedItem.id];
  },
);

export const getSelectedElement = createSelector(
  (state: ReduxState) => state.dashboardInteractions.selectedItem,
  (state: ReduxState) => state.dashboardEditConfig.config?.elements,
  (selectedItem, elements) => {
    if (!selectedItem || selectedItem.type === DASHBOARD_ELEMENT_TYPES.DATA_PANEL) return;
    return elements?.[selectedItem.id];
  },
);

export const getSelectedDpDatasetId = createSelector(
  (state: ReduxState) => getSelectedDataPanel(state),
  (state: ReduxState) => getEditableDatasets(state),
  (dataPanel, datasets) => (dataPanel ? datasets[dataPanel.table_id]?.id : undefined),
);

export const getAreFiltersDisabled = createSelector(
  (state: DashboardStates) => state.dashboardInteractions.interactionsInfo,
  (state: DashboardStates) => state.dashboardData.dataPanelData,
  (state: DashboardStates) => state.dashboardData.dashboardLoaded,
  (interactionsInfo, dataPanelData, dashboardLoaded) => {
    if (interactionsInfo.disableInputs) return true;
    if (!interactionsInfo.disableFiltersWhileLoading) return false;

    // Until initial load is done we know they should be disabled
    return (
      !dashboardLoaded ||
      Object.values(dataPanelData).some(
        (data) => data?.loading || !!data?.outstandingSecondaryDataRequests,
      )
    );
  },
);

export const getDatasetLinks = createSelector(
  (state: DashboardStates) =>
    'dashboardEditConfig' in state ? getSelectedElement(state) : undefined,
  (_: unknown, dataset: ResourceDataset | undefined) => dataset,
  (_: unknown, __: unknown, operationType: OPERATION_TYPES) => operationType,
  (selectedElement, dataset, operationType) => {
    if (
      !selectedElement ||
      !dataset ||
      isInvalidLinkCombo(selectedElement.element_type, operationType)
    )
      return;

    return getDashboardLinkForDataset(dataset, selectedElement);
  },
);

const getAppEditableSectionLayout = createSelector(
  (state: ReduxState) => state.dashboardEditConfig.config?.editable_section?.default_layout,
  (state: ReduxState) => state.dashboardEditConfig.editableSectionLayout,
  (state: ReduxState) => state.dashboardInteractions.interactionsInfo.isEditing,
  (defaultLayout, previewLayout, isEditingDashboard) =>
    isEditingDashboard ? defaultLayout : previewLayout ?? defaultLayout,
);

export const getEditableSectionLayout = createSelector(
  (state: DashboardStates) => {
    if (state.dashboardLayout.requestInfo.type === 'embedded') {
      return state.embedDashboard.currentEditableSectionLayout;
    }

    // Make sure state is of ReduxState
    return 'dashboardEditConfig' in state ? getAppEditableSectionLayout(state) : undefined;
  },
  (layout) => layout,
);

export const getEditableSectionConfig = createSelector(
  (state: DashboardStates) => {
    if (state.dashboardLayout.requestInfo.type === 'embedded') {
      return state.embedDashboard.dashboardVersion?.configuration.editable_section;
    }

    // Make sure state is of ReduxState
    return 'dashboardEditConfig' in state
      ? state.dashboardEditConfig.config?.editable_section
      : undefined;
  },
  (config) => config,
);

export const getDatasetsByFidoId = createSelector(
  (state: ReduxState) => {
    return keyBy(
      Object.values(state.dashboardEditConfig.config?.datasets ?? {}),
      (dataset) => dataset.fido_id ?? '',
    );
  },
  (datasetsByFidoId) => datasetsByFidoId,
);

export const getArchetypeProperties = createSelector(
  (state: DashboardStates) => {
    const archetypeProperties =
      state.dashboardLayout.requestInfo.type === 'embedded'
        ? state.embedDashboard.team?.archetype_properties
        : 'dashboardEditConfig' in state
        ? state.teamData.data?.archetype_properties
        : undefined;
    return archetypeProperties;
  },
  (archetypeProperties) => new Set(archetypeProperties?.map((prop) => prop.name)),
);

export const getSchemaTablesMap = createSelector(
  (state: ReduxState) => !!state.currentUser.team?.feature_flags.use_fido,
  (state: ReduxState) => state.parentSchemas.schemaTablesMap,
  (state: ReduxState) => state.fido.fidoDaos,
  (useFido, schemaTablesMap, fidoDaos) => {
    if (!useFido) return RD.isSuccess(schemaTablesMap) ? schemaTablesMap.data : null;

    return RD.isSuccess(fidoDaos) ? fidoDaos.data.schemaTablesMap : null;
  },
);

export const getParentSchemaSourceTypes = createSelector(
  (state: ReduxState) => state.dataSource.dataSources,
  (dataSources) => {
    const parentSchemaSourceTypes: Record<number, string> = {};

    RD.getOrDefault(dataSources, []).forEach(
      (d) => (parentSchemaSourceTypes[d.parent_schema_id] = d.source_type),
    );

    return parentSchemaSourceTypes;
  },
);

export const getCurrentDashboard = createSelector(
  (state: ReduxState) => state.dashboard.currentDashboard,
  (currentDashboard) => RD.getOrDefault(currentDashboard, undefined),
);

export const getCurrentReportBuilder = createSelector(
  (state: ReduxState) => state.reportBuilder.currentReportBuilder,
  (currentReportBuilder) => RD.getOrDefault(currentReportBuilder, undefined),
);

export const selectShouldUseJobQueue = (state: AllStates) =>
  'dashboardLayout' in state ? state.dashboardLayout.requestInfo.useJobQueue : false;

export const selectEnableScreenshotExports = (state: AllStates) =>
  'dashboardLayout' in state ? !!state.dashboardLayout.requestInfo.enableScreenshotExports : false;

export const selectEnableEmailExports = (state: AllStates) =>
  'dashboardLayout' in state ? !!state.dashboardLayout.requestInfo.enableEmailExports : false;

export const getEnableScreenshotExportReportBuilder = createSelector(
  (state: ReportBuilderReduxState) =>
    state.embeddedReportBuilder.team?.entitlements.enable_screenshot_exports,
  (state: ReportBuilderReduxState) =>
    state.embeddedReportBuilder.team?.feature_flags.enable_report_builder_image_export,
  (enable_screenshot_exports, enable_report_builder_image_export) =>
    enable_screenshot_exports && enable_report_builder_image_export,
);

export const getStickyHeaderConfigWithDrilldowns = createSelector(
  (state: ReduxState) => state.dashboardEditConfig.config?.dashboard_page_layout_config,
  (state: ReduxState) => state.dashboardEditConfig.versionHierarchy,
  (noVersionsDashboardPageLayoutConfig, versionHierarchy) => {
    if (RD.isSuccess(versionHierarchy)) {
      return versionHierarchy.data.dashboardVersions[versionHierarchy.data.rootDashboardId]
        .configuration.dashboard_page_layout_config?.stickyHeader;
    }
    return noVersionsDashboardPageLayoutConfig?.stickyHeader;
  },
);

export const getDashboardEditConfigWithDrilldowns = createSelector(
  (state: ReduxState) => state.dashboardEditConfig.config,
  (state: ReduxState) => state.dashboardEditConfig.versionHierarchy,
  (state: ReduxState) => state.dashboardEditConfig.currentDashboardId,
  (config, versionHierarchy, currentDashboardId) => {
    if (RD.isSuccess(versionHierarchy) && currentDashboardId) {
      return versionHierarchy.data.dashboardVersions[currentDashboardId].configuration;
    }
    return config;
  },
);

export const getEmbedDashboardConfigSelector = createSelector(
  (state: EmbedReduxState) => state.dashboardLayout.requestInfo.type,
  (state: EmbedReduxState) => state.embedDashboard?.dashboardVersion?.configuration,
  (state: EmbedReduxState) => state.embedDashboard?.hiddenElements,
  (state: EmbedReduxState) => state.embedDashboard?.currentEditableSectionLayout,
  (type, config, hiddenElements, layout) => {
    if (type !== 'embedded') return;
    return getEmbedDashboardConfig(config, hiddenElements, layout);
  },
);

export const getDashboardIdToNameMap = createSelector(
  (state: DashboardStates | ReportBuilderStates) => {
    return 'dashboard' in state
      ? RD.getOrDefault(state.dashboard.dashboardHierarchy, undefined)
      : undefined;
  },
  (dashboardHierarchy) => {
    if (!dashboardHierarchy) {
      return {};
    }

    return mapValues(dashboardHierarchy.dashboards, (dashboard) => dashboard.name);
  },
);
