import { Menu, MenuItem } from '@blueprintjs/core';
import * as Popover from '@radix-ui/react-popover';
import cx from 'classnames';
import { FC, useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { sprinkles } from 'components/ds';
import { ROUTE_PROVIDERS } from 'constants/routes';
import { CustomMenuOptionConfig, V2TwoDimensionChartInstructions } from 'constants/types';
import { embedSprinkles } from 'globalStyles/sprinkles.css';
import { ChartMenuInfo, getIsIframe, setChartMenu } from 'reducers/dashboardLayoutReducer';
import { DashboardStates, ReportBuilderStates } from 'reducers/rootReducer';
import { fetchDrilldownDataThunk } from 'reducers/thunks/dashboardDataThunks/fetchDataPanelThunks';
import { DrilldownVariable } from 'types/dashboardTypes';
import { DrilldownEntryPointInfo } from 'types/dataPanelTemplate';
import { getColorColumn } from 'utils/colorColUtils';

type Props = {
  canViewUnderlyingData: boolean;
  chartMenuInfo: ChartMenuInfo;
  dataPanelId: string;
  instructions: V2TwoDimensionChartInstructions | undefined;
  customMenuOptions?: CustomMenuOptionConfig[];
  drilldownVar?: DrilldownVariable;
  selectedColorColName?: string;
  excludedCategories?: (string | number)[];
  drilldownEntryPoints: Record<string, DrilldownEntryPointInfo>;
  dashboardIdToNameMap: Record<string, string>;

  setCategorySelect?: (category: string, colorColumn?: string) => void;
};

export const DrilldownChartMenu: FC<Props> = ({
  canViewUnderlyingData,
  chartMenuInfo,
  dataPanelId,
  customMenuOptions,
  instructions,
  selectedColorColName,
  excludedCategories,
  setCategorySelect,
  drilldownVar,
  drilldownEntryPoints,
  dashboardIdToNameMap,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { layoutId, isIframe } = useSelector(
    (state: DashboardStates | ReportBuilderStates) => ({
      layoutId: 'dashboardLayout' in state ? state.dashboardLayout.layoutId : null,
      isIframe: 'dashboardLayout' in state ? getIsIframe(state.dashboardLayout) : false,
    }),
    shallowEqual,
  );

  const onCustomMenuOptionClicked = useCallback(
    (jsEvent: string, category: string, subCategory?: string) => {
      const detail = { category, color: subCategory };
      if (isIframe) {
        window.parent.postMessage({ event: jsEvent, detail }, '*');
      } else {
        window.dispatchEvent(new CustomEvent(jsEvent, { detail }));
      }
    },
    [isIframe],
  );

  const { category, subCategory, chartX, chartY } = chartMenuInfo;
  const hasColorOptions = !!instructions?.colorColumnOptions?.length;
  const isCategorySelected =
    drilldownVar?.category === category && drilldownVar?.color === subCategory;

  const matchingDrilldownEntryPoints = useMemo(() => {
    // There might still be some old data panels that do not have drilldown entry points defined
    // (should be an empty object in that case but since we did not do a migration, it might be
    // undefined).
    if (!drilldownEntryPoints) {
      return [];
    }

    return Object.entries(drilldownEntryPoints).filter(([, entryPointInfo]) => {
      const entryPointSourceColumns = entryPointInfo.sourceChartColumns;
      const doesSelectedColumnsMatch =
        (entryPointSourceColumns[0] === category && !subCategory) ||
        entryPointSourceColumns[1] === subCategory;
      return doesSelectedColumnsMatch;
    });
  }, [drilldownEntryPoints, category, subCategory]);

  const onUnderlyingDataClick = (category: string, subCategory?: string) => {
    const categoryColumn = instructions?.categoryColumn;
    if (!categoryColumn) return;

    const colorColumn = getColorColumn(instructions, selectedColorColName);

    dispatch(
      fetchDrilldownDataThunk({
        dataPanelId,
        categoryColumn,
        category,
        subCategoryColumn: colorColumn,
        subCategory: hasColorOptions ? subCategory : undefined,
        excludedCategories: category === 'Other' ? excludedCategories : undefined,
      }),
    );
  };

  if (!canViewUnderlyingData && !setCategorySelect && !customMenuOptions?.length) return null;

  return (
    <Popover.Root onOpenChange={(open) => !open && dispatch(setChartMenu(null))} open={true}>
      <Popover.Anchor asChild>
        <div
          className={sprinkles({ position: 'absolute' })}
          style={{ left: chartX, top: chartY }}
        />
      </Popover.Anchor>
      <Popover.Portal container={layoutId ? document.getElementById(layoutId) : undefined}>
        {/* Added key so it repaints if another part of the chart is clicked */}
        <Popover.Content
          align="start"
          key={`${chartX}-${chartY}`}
          onClick={() => dispatch(setChartMenu(null))}
          side="right"
          style={{ zIndex: 10 }}>
          <Menu
            className={cx(
              sprinkles({ boxShadow: 'md' }),
              embedSprinkles({ body: 'primaryWithoutColor' }),
            )}>
            {canViewUnderlyingData ? (
              <MenuItem
                onClick={() => onUnderlyingDataClick(category, subCategory)}
                text="View Underlying Data"
              />
            ) : null}
            {setCategorySelect ? (
              <MenuItem
                onClick={() =>
                  setCategorySelect(category, hasColorOptions ? subCategory : undefined)
                }
                text={isCategorySelected ? 'Remove Filter' : 'Filter Category'}
              />
            ) : null}
            {matchingDrilldownEntryPoints.map(([entryPointId, entryPointInfo]) => (
              <MenuItem
                key={entryPointId}
                onClick={() => {
                  history.push(
                    ROUTE_PROVIDERS.DASHBOARD(`${entryPointInfo.destinationDashboardId}`),
                  );
                }}
                text={`Drill into chart ${
                  dashboardIdToNameMap[entryPointInfo.destinationDashboardId]
                }`}
              />
            ))}
            {customMenuOptions?.map((menuOption) =>
              menuOption.name && menuOption.customJSEventName ? (
                <MenuItem
                  onClick={() =>
                    onCustomMenuOptionClicked(
                      menuOption.customJSEventName as string,
                      category,
                      subCategory,
                    )
                  }
                  text={menuOption.name}
                />
              ) : null,
            )}
          </Menu>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
